
// TAB & ACCORDION

function bigToSmall() {
  $('.tab-widget__tab-content--active')
    .removeClass('tab-widget__tab-content--active');
};

function smallToBig() {
  $('.tab-widget__tab-content')
    .first()
    .addClass('tab-widget__tab-content--active');
  $('.removeStyle')
    .removeAttr('style');
};


// HOMEPAGE BG RESIZER

function resizeHomepageBg(isXs) {
  var newHeight = (isXs)
      ? $('.slider-pagination').offset().top + $('.slider-pagination').height() + 50
      : $('.slider').offset().top + $('.slider').height() - 90;
  $('.header-bg').stop().animate({
    height: newHeight + 'px'
  });
}


// BREAKPOINT CHECKER
var sizeWas;

(function($, viewport){
  $(document).ready(function() {
    // remember current state
    sizeWas = viewport.current();
    // check if page contains tabs
    var hasTabs = ($('.js-tab-widget').length === 0) ? false : true;
    // check if page is the homepage
    var isHomepage = ($('.homepage').length === 0) ? false : true;
    // execute functions once on document ready
    if (sizeWas === 'xs' || sizeWas === 'sm') {bigToSmall();}
    if ($('.homepage').length > 0) {resizeHomepageBg(viewport.is('xs'));}
    // on resize
    $(window).resize(
      // debouncer
      viewport.changed(function() {
        // if the current state is different than the previous state...
        if (sizeWas !== viewport.current() || viewport.is('xs')) {
          // if homepage
          if (isHomepage) {
            resizeHomepageBg(viewport.is('xs'));
          }
          // if the new state is 'xs' or 'sm'...
          if (viewport.is('<md') && (sizeWas === 'md' || sizeWas === 'lg')) {
            bigToSmall();
          }
          // otherwise the new state is 'md' or 'lg'...
          else if (viewport.is('>sm') && (sizeWas === 'xs' || sizeWas === 'sm')) {
            smallToBig();
          }
          sizeWas = viewport.current();
        }
      }, 150)
    );
  });
  $(window).on('load', function() {
    // remember current state
    sizeWas = viewport.current();
    // execute functions once on window load
    if ($('.homepage').length > 0) {resizeHomepageBg(viewport.is('xs'));}
  });
})(jQuery, ResponsiveBootstrapToolkit);





$(function() {

  // MULTISELECT
  var selectAll = true,
      enableFiltering = false,
      enableCaseInsensitiveFiltering = false,
      userAgent = navigator.userAgent || navigator.vendor || window.opera,
      iOS = !!(/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream);
  if (iOS) {
    $('.multiselect').addClass('form-control');
  } else {
    $('.multiselect').each(function(index) {
      switch ($(this).attr('name')) {
        case 'subject-filters':
          enableFiltering = false;
          enableCaseInsensitiveFiltering = false;
          break;
        case 'grade-filters':
          enableFiltering = false;
          enableCaseInsensitiveFiltering = false;
          break;
        case 'medium-filters':
        case 'region-filters':
          enableFiltering = false;
          break;
        case 'concept-filters':
          enableFiltering = true;
          enableCaseInsensitiveFiltering = true;
          break;
      }
      $(this).attr('id','multiselect' + index);
      $('#multiselect' + index).multiselect({
        nonSelectedText: 'Any ' + $(this).siblings().text(),
        selectAllNumber: false,
        numberDisplayed: 1,
        enableFiltering: enableFiltering,
        enableCaseInsensitiveFiltering: enableCaseInsensitiveFiltering,
        maxHeight: 300,
        includeSelectAllOption: selectAll
      });
    });
  }


  // ADDITIONAL ACCORDION EVENTS

  $('button.tab-widget__accordion-toggle').click(function() {
    if ('false' === $(this).attr('aria-expanded')) {
      $(this).attr('aria-expanded', 'true');
    } else {
      $(this).attr('aria-expanded', 'false');
    }
    $(this)
      .parents('.tab-widget__tab-content')
      .toggleClass('shown')
      .find('.tab-widget__tab-content-container')
      .slideToggle();
  })

  $('.filterToggle').click(function() {

    if ('false' === $(this).attr('aria-expanded')) {
      $(this).attr('aria-expanded', 'true');
      $(this).find('.js-filter-text').text('Hide Filters');
    } else {
      $(this).attr('aria-expanded', 'false');
      $(this).find('.js-filter-text').text('Show Filters');
    }

    $(this)
      .toggleClass('shown')
      .parents('.archiveFilters')
      .find('.archiveFilter-fields')
      .slideToggle();
  });


  // SLIDER

  function swiperA11y(n, updateFocus) {
    $('.swiper-slide a, .swiper-slide iframe').attr('tabindex','-1');
    $('.swiper-slide:nth-child(' + n + ') a, .swiper-slide:nth-child(' + n + ') iframe').attr('tabindex','0');
    if (updateFocus === true) {
      if ($('.swiper-slide:nth-child(' + n + ') iframe').length > 0) {
        $('.swiper-slide:nth-child(' + n + ') iframe').first().focus();
      } else {
        $('.swiper-slide:nth-child(' + n + ') a').first().focus();
      }
    }
  };

  $('.homepage').on('click', '.swiper-pagination-bullet', function () {
    swiperA11y($(this).index() + 1, true);
  });

  function paginationToggle() {
    $('.swiper-pagination-bullet').first().addClass('swiper-pagination-bullet-active');
  }

  var mySwiper = new Swiper('.swiper-container', {
    effect: 'fade',
    fade: {
      crossFade: true
    },
    a11y: true,
    zoom: false,
    autoHeight: true,
    autoplay: 6500,
    pagination: '.slider-pagination',
    paginationType: 'bullets',
    paginationClickable: true,
    paginationElement: 'button',
    autoplayDisableOnInteraction: true,
    updateOnImagesReady: false,
    onInit: function(swiper) { paginationToggle(); swiperA11y(1, false); },
    onSlideChangeEnd: function(swiper) { resizeHomepageBg(sizeWas === 'xs') }
  });

  var slideStopper = setInterval(function() {
    if ($('body').hasClass('homepage')) {
      if ($(window).scrollTop() > 400) {
        mySwiper.stopAutoplay();
        clearInterval(slideStopper);
      }
    }
  }, 1500);

  $(".swiper-button-pause").click(function(){
	mySwiper.stopAutoplay();
	clearInterval(slideStopper);
	$(".swiper-button-pause").addClass( "SliderPause" );
	$(".swiper-button-play").removeClass( "SliderPlay" );
  });
  
    $(".swiper-button-play").click(function(){
	mySwiper.startAutoplay();
	$(".swiper-button-play").addClass( "SliderPlay" );
	$(".swiper-button-pause").removeClass( "SliderPause" );
  });





  // LIGHTBOX GALLERY

  function fixAriaAttributes() {
    $('.modaal-gallery-item').each(function(i) {
      if ((i > 0) && $('body').hasClass('workOfArt')) {
        $(this).find('.modaal-gallery-label').html('<b>' + $('#' + $('.modaalGallery img').eq(i).attr('aria-labelledby')).text() + '</b><br/>' + $('#' + $('.modaalGallery img').eq(i).attr('aria-describedby')).text());
      } else {
        $(this).find('.modaal-gallery-label').html('<b>' + $('#' + $('.modaalGallery img').eq(i).attr('aria-labelledby')).text() + '</b>');
      }
      $(this).attr('aria-label', $('#' + $('.modaalGallery img').eq(i).attr('aria-labelledby')).text());
      $(this).attr('aria-describedby', $('.modaalGallery img').eq(i).attr('aria-describedby'));
    });
  }

  $('.modaalGallery').modaal({
    type: 'image',
    after_open: fixAriaAttributes
  });

  $('.modaalGalleryTrigger').click(function() {
    $(this).parents('.componentGallery').find('a.modaalGallery').first().click();
  });

  // LIGHTBOX IMAGES
  $('.modaalImage').modaal({
    type: 'image'
  });



  // LINK BUBBLER
  // Enables whole cards to be clickable, even if they have different child links

  // toggle hover class when parent is hovered
  // tie into listener for body since cards can be loaded w/ ajax.

  $('body').on({
    mouseenter: function() {
       $(this).addClass('hover');
    },
    mouseleave: function() {
       $(this).removeClass('hover');
    }
  }, '.linkBubbler-parent');

  $('body').on('click', '.linkBubbler-parent', function(e) {
    if (!$(e.target).is('.linkBubbler-stop')) {
      window.location.href = $(this).find('.linkBubbler-source').attr('href');
    }
  });



});
