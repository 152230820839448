/**
 * Returns true or false based on current Bootstrap breakpoint
 */
function isBreakpoint(alias) {
  return $('.device-' + alias).is(':visible');
}

/**
 * Returns current breakpoint as string based on isBreakpoint
 */
function getBreakpoint() {
  if(isBreakpoint('lg')) { return 'lg'; }
  if(isBreakpoint('md')) { return 'md'; }
  if(isBreakpoint('sm')) { return 'sm'; }
  return 'xs';
}

/**
 * AJAX function for archive pages.
 * Queries and API endpoint with different params to load the results.
 * Gets called from initLoadPosts().
 * See archive.php for required data-params.
 */
var totalReturned = 0, totalPages, totalPosts;
function loadPosts(options) {
  var wrapper = $('#js-posts-wrapper'),
      scrollTop = 0,
      row = $('#js-posts-row'),
      type = wrapper.attr('data-type') || '',
      search = options.search || '',
      concept = options.concept || '',
      grade = options.grade || '',
      region = options.region || '',
      nationality = options.nationality || '',
      medium = options.medium || '',
      subject = options.subject || '',
      resource_tag = options.resource_tag || '',
      event_cat = options.event_cat || '',
      event_is_upcoming = ( $('#upcoming-past-select').val() === 'upcoming' ),
      resource_type = options.resource_type || '',
      offset = parseInt( wrapper.attr('data-offset') ) || '',
      current = parseInt( wrapper.attr('data-index') ) || '',
      url = '/wp-json/ncma/v1/'+type+'?posts_per_page='+offset+'&paged='+(current+1);

  // Search queries
  if(search.length > 0) url += '&se='+search;

  // Shared tax queries
  if(concept.length > 0) url += '&tax_query[0][taxonomy]=concept&tax_query[0][field]=slug'+concept+'&tax_query[0][operator]=IN';
  if(grade.length > 0) url += '&tax_query[1][taxonomy]=grade&tax_query[1][field]=slug'+grade+'&tax_query[1][operator]=IN';
  if(region.length > 0) url += '&tax_query[2][taxonomy]=region&tax_query[2][field]=slug'+region+'&tax_query[2][operator]=IN';
  if(nationality.length > 0) url += '&tax_query[7][taxonomy]=nationality&tax_query[7][field]=slug'+nationality+'&tax_query[7][operator]=IN';
  if(resource_tag.length > 0) url += '&tax_query[8][taxonomy]=resource_tag&tax_query[8][field]=slug'+resource_tag+'&tax_query[8][operator]=IN';

  // "artwork" type queries
  if(medium.length > 0) url += '&tax_query[3][taxonomy]=medium&tax_query[3][field]=slug'+medium+'&tax_query[3][operator]=IN';

  // "lesson-plans" type queries
  if(subject.length > 0) url += '&tax_query[4][taxonomy]=subject&tax_query[4][field]=slug'+subject+'&tax_query[4][operator]=IN';

  // "events" type queries
  if(event_cat.length > 0) url += '&tax_query[5][taxonomy]=event_cat&tax_query[5][field]=slug'+event_cat;
  if(type === 'events') {
    if(event_is_upcoming) {
      url += '&events_display=upcoming&orderby=meta_value&meta_key=event_date_time&order=ASC&meta_type=DATETIME';
    } else {
      url += '&events_display=past&orderby=meta_value&meta_key=event_date_time&order=DESC&meta_type=DATETIME';
    }
  }

  // "resources" type queries
  if(resource_type.length > 0) url += '&tax_query[6][taxonomy]=resource_type&tax_query[6][field]=slug'+resource_type+'&tax_query[6][operator]=IN';

  // get scrollTop
  scrollTop = $(window).scrollTop();

  // parse the JSON data from specified url
  $.getJSON(url, function(data,status,xhr) {

    totalPages = xhr.getResponseHeader('x-wp-totalpages');
    totalPosts = xhr.getResponseHeader('x-wp-total');

    $(data).each(function() {
      row.append(this.html);
      totalReturned++;
    });

  }).done(function() {
    $('#js-posts-load-more').removeClass('hidden').attr('data-finished', (totalReturned >= totalPosts)).button('reset');
    if(totalReturned >= totalPosts) {
      $('#js-posts-load-more').addClass('hidden')
    }
    $('#ajaxLoading').hide();

    if(totalReturned === 0) {
      row.append('<div class="col-xs-12">No results matched your filters.</div>');
    }

    $('.equalHeight').matchHeight();

    $(window).scrollTop(scrollTop);

  }).fail(function() {
    row.append('<div class="alert alert-danger">There was an error loading the posts.</div>');
  });

  wrapper.attr('data-index', current+1);
}

/**
 * Initialize AJAX and load the initial set of posts.
 * Contains all of the UI events for filtering.
 */
function initLoadPosts() {
  var wrapper = $('#js-posts-wrapper'),
      row = $('#js-posts-row'),
      concept = wrapper.attr('data-concept'),
      grade = wrapper.attr('data-grade'),
      region = wrapper.attr('data-region'),
      nationality = wrapper.attr('data-nationality'),
      medium = wrapper.attr('data-medium'),
      subject = wrapper.attr('data-subject'),
      resource_type = wrapper.attr('data-resource_type'),
      resource_tag = wrapper.attr('data-resource_tag'),
      options = {
        type: wrapper.attr('data-type') || '',
        concept: concept ? '&tax_query[0][terms][0]='+concept : '',
        grade: grade ? '&tax_query[1][terms][0]='+grade : '',
        region: region ? '&tax_query[2][terms][0]='+region : '',
        nationality: nationality ? '&tax_query[7][terms][0]='+nationality : '',
        medium: medium ? '&tax_query[3][terms][0]='+medium : '',
        subject: subject ? '&tax_query[4][terms][0]='+subject : '',
        resource_type: resource_type ? '&tax_query[6][terms][0]='+resource_type : '',
        resource_tag: resource_tag ? '&tax_query[8][terms][0]=' + resource_tag : ''
      };

  /*
   * Load the initial set of posts.
   */
   
   
    var concept = '',
        target = $( 'select[name=concept-filters] option:selected' );
    $.each( target, function(key,value) {
      concept += '&tax_query[0][terms]['+key+']='+this.value;
    });
    options.concept = concept;
	
	var grade = '',
        target = $( 'select[name=grade-filters] option:selected' );
    $.each( target, function(key,value) {
      grade += '&tax_query[1][terms]['+key+']='+this.value;
    });
    options.grade = grade;
	
    var subject = '',
        target = $( 'select[name=subject-filters] option:selected' );
    $.each( target, function(key,value) {
      subject += '&tax_query[4][terms]['+key+']='+this.value;
    });
    options.subject = subject;
	
	//search input
	options.search = $('#ajax-search').val();
	//clear
    clearPosts();
	//load
    loadPosts( options );
	
  //loadPosts(options);

  /*
   * Clear posts function.
   * Run this before any new call to loadPosts().
   */
  function clearPosts() {
    totalReturned = 0;
    $('#ajaxLoading').show();
    $('#js-posts-load-more').addClass('hidden');
    wrapper.attr('data-index', 0);
    row.empty();
  }

  /*
   * Load more button
   */
  $('#js-posts-load-more').on('click', function(e) {
    e.preventDefault();
    loadPosts(options);
    $(this).button('loading');
  });

  /*
   * Ajax search
   */
  $('#ajax-search').on('keyup paste', debounce(function(){
    options.search = $('#ajax-search').val();
    clearPosts();
    loadPosts(options);
  }, 500));

  /*
   * Concept filter
   * tax_query index = 0
   */
  $( 'select[name=concept-filters]' ).change( function(){
    var concept = '',
        target = $( 'select[name=concept-filters] option:selected' );
    $.each( target, function(key,value) {
      concept += '&tax_query[0][terms]['+key+']='+this.value;
    });
    options.concept = concept;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Grade filter
   * tax_query index = 1
   */
  $( 'select[name=grade-filters]' ).change( function(){
    var grade = '',
        target = $( 'select[name=grade-filters] option:selected' );
    $.each( target, function(key,value) {
      grade += '&tax_query[1][terms]['+key+']='+this.value;
    });
    options.grade = grade;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Region filter
   * tax_query index = 2
   */
  $( 'select[name=region-filters]' ).change( function(){
    var region = '',
        target = $( 'select[name=region-filters] option:selected' );
    $.each( target, function(key,value) {
      region += '&tax_query[2][terms]['+key+']='+this.value;
    });
    options.region = region;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Nationality filter
   * tax_query index = 7
   */
  $( 'select[name=nationality-filters]' ).change( function(){
    var nationality = '',
        target = $( 'select[name=nationality-filters] option:selected' );
    $.each( target, function(key,value) {
      nationality += '&tax_query[7][terms]['+key+']='+this.value;
    });
    options.nationality = nationality;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Medium filter
   * tax_query index = 3
   */
  $( 'select[name=medium-filters]' ).change( function(){
    var medium = '',
        target = $( 'select[name=medium-filters] option:selected' );
    $.each( target, function(key,value) {
      medium += '&tax_query[3][terms]['+key+']='+this.value;
    });
    options.medium = medium;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Subject filter
   * tax_query index = 4
   */
  $( 'select[name=subject-filters]' ).change( function(){
    var subject = '',
        target = $( 'select[name=subject-filters] option:selected' );
    $.each( target, function(key,value) {
      subject += '&tax_query[4][terms]['+key+']='+this.value;
    });
    options.subject = subject;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Event Category filter
   * tax_query index = 5
   */
  $( 'select[name=event_cat-filters]' ).change( function(){
    var event_cat = '',
        target = $( 'select[name=event_cat-filters] option:selected' );
    $.each( target, function(key,value) {
      event_cat += '&tax_query[5][terms]['+key+']='+this.value;
    });
    options.event_cat = event_cat;
    clearPosts();
    loadPosts( options );
  });

  /*
   * Resource tag filter
   * tax_query index = 8
   */
  $('select[name=resource_tag-filters]').change(function () {
    var resource_tag = "",
      target = $("select[name=resource_tag-filters] option:selected");
    $.each(target, function (key, value) {
      resource_tag += '&tax_query[8][terms][' + key + ']=' + this.value;
    });
    options.resource_tag = resource_tag;
    clearPosts();
    loadPosts(options);
  });

  /*
   * Event upcoming/past select box
   */
  $( '#upcoming-past-select' ).change( function(){
    options.events_display = $(this).val();
    clearPosts();
    loadPosts( options );
  });

}// initLoadPosts()

/**
 * NCMA Visitor Survey (Popup Maker + Gravity Forms)
 */
function ncmaVisitorSurvey() {
  if (Cookies.get('pum-2372') && Cookies.get('pum-2374')) {
    return;
  }

  if (!Cookies.get('pum-2372') && !Cookies.get('pum-2374')) {
    // Trigger opening a random popup if neither survey form has been submitted.
    var surveyIds = [2372, 2374];
    var randomSurvey = surveyIds[Math.floor(Math.random() * surveyIds.length)];
    PUM.open(randomSurvey);
    return;
  }

  // Open one of two surveys user has not answered yet.
  if (Cookies.get('pum-2372') === 'true') {
    PUM.open(2374);
  }

  if (Cookies.get('pum-2374') === 'true') {
    PUM.open(2372);
  }
}

/**
 * Handy function to debounce functions.
 * Example use in #ajax-search.
 */
function debounce(fn, duration) {
  var timer;
  return function(){
    clearTimeout(timer);
    timer = setTimeout(fn, duration);
  }
}

/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $(window).on('load', function() {
          if (typeof PUM !== 'undefined') {
            ncmaVisitorSurvey();
          }
        });

        $(window).on('load resize', function(){
          if( !isBreakpoint('xs') ) {
            $('.mainHeader-mainNav .dropdown > a').focus(function(){
              var $this = $(this);
              $this.parent().addClass('open').siblings().removeClass('open');
            });
            $('.mainHeader-mainNav .dropdown-menu > li:last-child').focusout(function(){
              $('.mainHeader-mainNav .dropdown').each(function(){
                $(this).removeClass('open');
              })
            });
          }
        });
      }
    },
    // Archive pages
    'archive': {
      finalize: function() {
        initLoadPosts();
      }
    },
    // Archive pages
    'single_artwork': {
      finalize: function() {
        $('#keep_reading').click(function(){
          if( $(this).hasClass('collapsed') ) {
            $(this).html("Read Less &uArr;");
          } else {
            $(this).html("Keep Reading &dArr;");
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
